var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.thePage.contents.ns_1_title ||
        _vm.thePage.contents.ns_1_description ||
        _vm.thePage.contents.ns_1_img
    )?_c('BaseThemeComponent',{staticClass:"white--text pt-5",attrs:{"name":"PageSectionNarrative","section-id":"1","bg-color":_vm.thePage.contents.ns_1_bg_color,"title-class":_vm.thePage.contents.ns_1_title_class,"text-cols":7,"fluid":""}},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md10":""}},[_c('BasePageContent',{staticClass:"r-headline",attrs:{"content-key":"ns_1_description","tag":"p"}}),(_vm.thePage.contents.ns_1_btn_text)?_c(_vm.thePage.contents.ns_1_btn_outline_color
              ? 'BaseButtonOutline'
              : 'BaseButton',{tag:"component",staticClass:"ml-0",attrs:{"outline-color":_vm.thePage.contents.ns_1_btn_outline_color,"color":_vm.thePage.contents.ns_1_btn_color,"to":_vm.thePage.contents.ns_1_btn_route,"href":_vm.thePage.contents.ns_1_btn_link}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_1_btn_text)+" ")]):_vm._e(),(_vm.thePage.contents.ns_1_btn2_text)?_c(_vm.thePage.contents.ns_1_btn2_outline_color
              ? 'BaseButtonOutline'
              : 'BaseButton',{tag:"component",staticClass:"ml-0",attrs:{"outline-color":_vm.thePage.contents.ns_1_btn2_outline_color,"color":_vm.thePage.contents.ns_1_btn2_color,"to":_vm.thePage.contents.ns_1_btn2_route,"href":_vm.thePage.contents.ns_1_btn2_link}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_1_btn2_text)+" ")]):_vm._e()],1)],1)],1):_vm._e(),_c('BasePageSection',{staticClass:"bubs-menu-section",attrs:{"id":"menu","py-2":"","fluid":"","full-width":""}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":""}},[_c('BasePageContent',{attrs:{"content-key":"catering_menu_title","default-content":"Catering","tag-class":"header-4 text-xs-center mb-4 bubs-charcoal--text","tag":"h1"}})],1)],1),_c('WithLayout',{attrs:{"slug":"bubs-catering"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var isLayoutLoading = ref.isLayoutLoading;
              var theLayout = ref.theLayout;
return [(!theLayout)?_c('BaseThemeComponent',{staticClass:"bubs-purple",attrs:{"name":"PageSection","title-class":"bubs-wax-paper--text header-2","title":"Sorry, we're having trouble finding this menu."}}):(!isLayoutLoading)?_c('LayoutGrid',{class:"catering-menu bubs-menu",attrs:{"layout":theLayout}}):_vm._e()]}}])})],1),_c('BaseThemeComponent',{attrs:{"name":"CateringFormSection"}}),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }