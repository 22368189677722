<template>
  <div>
    <BaseThemeComponent
      v-if="
        thePage.contents.ns_1_title ||
          thePage.contents.ns_1_description ||
          thePage.contents.ns_1_img
      "
      name="PageSectionNarrative"
      section-id="1"
      class="white--text pt-5"
      :bg-color="thePage.contents.ns_1_bg_color"
      :title-class="thePage.contents.ns_1_title_class"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_1_description"
            tag="p"
            class="r-headline"
          />
          <component
            :is="
              thePage.contents.ns_1_btn_outline_color
                ? 'BaseButtonOutline'
                : 'BaseButton'
            "
            v-if="thePage.contents.ns_1_btn_text"
            :outline-color="thePage.contents.ns_1_btn_outline_color"
            :color="thePage.contents.ns_1_btn_color"
            class="ml-0"
            :to="thePage.contents.ns_1_btn_route"
            :href="thePage.contents.ns_1_btn_link"
          >
            {{ thePage.contents.ns_1_btn_text }}
          </component>
          <component
            :is="
              thePage.contents.ns_1_btn2_outline_color
                ? 'BaseButtonOutline'
                : 'BaseButton'
            "
            v-if="thePage.contents.ns_1_btn2_text"
            :outline-color="thePage.contents.ns_1_btn2_outline_color"
            :color="thePage.contents.ns_1_btn2_color"
            class="ml-0"
            :to="thePage.contents.ns_1_btn2_route"
            :href="thePage.contents.ns_1_btn2_link"
          >
            {{ thePage.contents.ns_1_btn2_text }}
          </component>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BasePageSection id="menu" py-2 fluid full-width class="bubs-menu-section">
      <VLayout row justify-center>
        <VFlex xs12>
          <BasePageContent
            content-key="catering_menu_title"
            default-content="Catering"
            tag-class="header-4 text-xs-center mb-4 bubs-charcoal--text"
            tag="h1"/></VFlex
      ></VLayout>
      <WithLayout v-slot="{ isLayoutLoading, theLayout }" slug="bubs-catering">
        <BaseThemeComponent
          v-if="!theLayout"
          name="PageSection"
          class="bubs-purple"
          title-class="bubs-wax-paper--text header-2"
          title="Sorry, we're having trouble finding this menu."
        />
        <LayoutGrid
          v-else-if="!isLayoutLoading"
          :layout="theLayout"
          :class="`catering-menu bubs-menu`"
        />
      </WithLayout>
    </BasePageSection>

    <BaseThemeComponent name="CateringFormSection" />

    <BaseMenuComponent name="MenuSnackbar" />
  </div>
</template>

<script>
import formatPhone from '@utils/format-phone'
import PageMixin from '@mixins/page'
import LayoutGrid from '@components/Layout/LayoutGrid'

export default {
  name: 'BubsCatering',
  components: { LayoutGrid },
  mixins: [PageMixin],
  data() {
    return {
      theLocation: {},
    }
  },
  methods: { formatPhone },
}
</script>

<style lang="scss">
.bubs-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left right .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'right' !important;
      grid-template-columns: 1fr !important;
    }
  }
}

.bubs-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
